export class BulkSMSStatus {
  public static VALUES: { [key: string]: BulkSMSStatus } = {
    OK: BulkSMSStatus.f(1, "OK"),
    INVALID_PARAMETER: BulkSMSStatus.f(-2, "Invalid Parameter"),
    ACCOUNT_SUSPENDED_DUE_TO_FRAUDULENT_MESSAGE: BulkSMSStatus.f(-3, "Account suspended due to fraudulent message"),
    INVALID_DISPLAY_NAME: BulkSMSStatus.f(-4, "Invalid Display name"),
    INVALID_MESSAGE_CONTENT: BulkSMSStatus.f(-5, "Invalid Message content"),
    INVALID_RECIPIENT: BulkSMSStatus.f(-6, "Invalid recipient"),
    INSUFFICIENT_UNIT: BulkSMSStatus.f(-7, "Insufficient unit"),
    UNKNOWN_ERROR: BulkSMSStatus.f(-10, "Unknown error"),
    UNAUTHENTICATED: BulkSMSStatus.f(401, "Unauthenticated"),
  };

  public static f(value: number, message: string): BulkSMSStatus {
    return new BulkSMSStatus(value, message);
  }

  private mValue: number;
  private mMessage: string;

  public constructor(value: number, message: string) {
    this.mValue = value;
    this.mMessage = message;
  }

  public value(): number {
    return this.mValue;
  }

  public message(): string {
    return this.mMessage;
  }

  public static resolve(value: number): BulkSMSStatus {
    for (const propKey in BulkSMSStatus.VALUES) {
      if (BulkSMSStatus.VALUES[propKey].value() === value) {
        return BulkSMSStatus.VALUES[propKey];
      }
    }
    return BulkSMSStatus.VALUES.UNKNOWN_ERROR;
  }
}

export enum BulkDLRStatus {
  SEND = "SENT",
  DELIVERED = "DELIVERED",
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
}

export interface BulkDLRData {
  /*-
   * {
   *    "sender_name": "Abby",
   *    "messageid": 1203951,
   *    "content": "Happy Sunday dear friends, this is Abby, a gift card vendor from China. If you received my text, plz save my number and call me on video, and Ill give you 500N as gift. Looking forward to trading with you!! This activity is valid from 7am to 19pm of July 21st. Wa.me\/+8615109508140",
   *    "disptachtime": "2024-07-21 08:15:11",
   *    "recipient": "2349151408659",
   *    "status": "DELIVERED",
   *    "remotemsgid": "msg_669cb46ae106f"
   * }
   */
  sender_name: string;
  messageid: number;
  content: string;
  disptachtime: string;
  recipient: string;
  status: BulkDLRStatus;
  remotemsgid: string;
}

export interface BulkSMSMessageReportResponse {
  //BulkSMSStatus
  status: number;
  data: BulkDLRData[];
  total: number;
  page: number;
  rows: number;
}

/*-
 * https://api.bulksmslive.com/v2/app/message/report?page=1
 * Request
 * {"sender":"test","recipient":"111222","startfrom":"2024-07-02","endfrom":"2024-07-25",
 * "delivery_status":"1"}
 *
 * Delivery Status:
 * DELIVERED: SMS delivered.
 * SENT: Possibly delivered; final report not provided.
 * DELIVEREDPP: SMS delivered with fixed numeric ID, despite DND.
 *
 * <select class="select">
 *   <option selected="selected" disabled="disabled">Select delivery status</option>
 *   <option value="1">Delivered</option>
 *   <option value="2">Sent</option>
 *   <option value="18">DeliveredPP</option>
 * </select>
 */
export interface BulkSMSMessageReportQuery {
  page: number;
  // messageIds: string[]; // TODO
  recipient: string;
  senderName: string;
  since: string;
  until: string;
  deliveryStatus: string;
}

export interface BulkSMSBalance {
  status: number;
  amount: number;
}

export interface SMSConsumeCodeModel {
  id: string;
  times: number;
  lastConsumeTime: number;
  createTime: number;
  updateTime: number;
}
