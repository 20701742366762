import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  FMPayGroupAccountBookModel,
  FMPayGroupAccountBookType,
  FMPayGroupModel,
  FMPayPagination,
  FMPayPaginationTimeRnageQuery,
  FMPayPaginationQuery,
  FMPayResponsePagination,
  PaymentOrderModel,
} from "fp/store/type";
import { Services } from "fp/store/Services";

export const groupAccountBookItemGroups = createAsyncThunk<FMPayPagination<FMPayGroupModel>, void>(
  "group.account.book/items.groups",
  async () => {
    return await Services.FMPayGroup.items();
  }
);

export const groupAccountBookPaginationPaymentOrderByGroupId = createAsyncThunk<
  FMPayResponsePagination<PaymentOrderModel>,
  FMPayPaginationTimeRnageQuery<{ groupId: string }>
>("group.account.book/pagination.payment.order.by.group.id", async (p) => {
  return await Services.FMPayOrder.paginationByGroup(p);
});

export const groupAccountBookCreate = createAsyncThunk<
  FMPayGroupAccountBookModel,
  {
    groupId: string;
    amount: string;
  }
>("group.account.book/create", async (p) => {
  const resp = await Services.FMPayGroupAccountBookModel.create({
    groupId: p.groupId,
    amount: p.amount,
  });
  return resp.data;
});

export const groupAccountBookPaginationByGroup = createAsyncThunk<
  FMPayResponsePagination<FMPayGroupAccountBookModel>,
  FMPayPaginationTimeRnageQuery<{ groupId: string }>
>("group.account.book/pagination.by.group", async (p) => {
  const model = await Services.FMPayGroupAccountBookModel.paginationByGroup(p);
  return model;
});

export const groupAccountBookPaginationAll = createAsyncThunk<
  FMPayResponsePagination<FMPayGroupAccountBookModel>,
  FMPayPaginationQuery<{
    since: number;
    until: number;
  }>
>("group.account.book/pagination.all", async (p) => {
  const resp = await Services.FMPayGroupAccountBookModel.paginationAll(p);
  return resp;
});

export interface GroupAccountBookState {
  orders: PaymentOrderModel[];
  groups: FMPayGroupModel[];
  model: FMPayGroupAccountBookModel | null;
  accountBooks: FMPayResponsePagination<FMPayGroupAccountBookModel>;
  loading: {
    orders: boolean;
    groups: boolean;
    accountBooks: boolean;
    create: boolean;
  };
}

const InitialState: GroupAccountBookState = {
  orders: [],
  groups: [],
  model: null,
  accountBooks: {
    success: true,
    message: "",
    status: "",
    data: {
      current: 1,
      size: 99999999,
      total: 0,
      pages: 0,
      latest: false,
      data: [],
    },
  },
  loading: {
    orders: false,
    groups: false,
    accountBooks: false,
    create: false,
  },
};

export const groupAccountBookSlice = createSlice({
  name: "groupAccountBook",
  initialState: { ...InitialState },
  reducers: {
    setPage(state, action: PayloadAction<{ current: number; size: number }>) {
      state.accountBooks.data.current = action.payload.current;
      state.accountBooks.data.size = action.payload.size;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(groupAccountBookCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(groupAccountBookCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(groupAccountBookCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });

    builder.addCase(groupAccountBookPaginationByGroup.pending, (state) => {
      state.loading.accountBooks = true;
    });
    builder.addCase(groupAccountBookPaginationByGroup.rejected, (state) => {
      state.loading.accountBooks = false;
    });
    builder.addCase(groupAccountBookPaginationByGroup.fulfilled, (state, action) => {
      state.loading.accountBooks = false;
      state.accountBooks = action.payload;
    });

    builder.addCase(groupAccountBookPaginationAll.pending, (state) => {
      state.loading.accountBooks = true;
    });
    builder.addCase(groupAccountBookPaginationAll.rejected, (state) => {
      state.loading.accountBooks = false;
    });
    builder.addCase(groupAccountBookPaginationAll.fulfilled, (state, action) => {
      state.loading.accountBooks = false;
      state.accountBooks = action.payload;
    });

    builder.addCase(groupAccountBookItemGroups.pending, (state) => {
      state.loading.groups = true;
    });
    builder.addCase(groupAccountBookItemGroups.rejected, (state) => {
      state.loading.groups = false;
    });
    builder.addCase(groupAccountBookItemGroups.fulfilled, (state, action) => {
      state.loading.groups = false;
      state.groups = action.payload.data;
    });

    builder.addCase(groupAccountBookPaginationPaymentOrderByGroupId.pending, (state) => {
      state.loading.orders = true;
    });
    builder.addCase(groupAccountBookPaginationPaymentOrderByGroupId.rejected, (state) => {
      state.loading.orders = false;
    });
    builder.addCase(groupAccountBookPaginationPaymentOrderByGroupId.fulfilled, (state, action) => {
      state.loading.orders = false;
      state.orders = action.payload.data.data;
    });
  },
});

export const { setPage } = groupAccountBookSlice.actions;
export default groupAccountBookSlice.reducer;
