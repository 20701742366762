import { ReactNode, useEffect, useState } from "react";
import { Alert, Button, Card, Checkbox, Form, Input, Radio, Select, Space, Spin, Typography, Upload } from "antd";
import { HttpClient } from "fp/store/HttpClient";
import { InboxOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { FileUploadModel, SendSMSSouceType } from "fp/store/type";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import { BulkSMSStatus } from "fp/components/sms/BulkSMSStatus";
import { bulkSMSCheckBalance, bulkSMSSend } from "fp/store/slice/bulkSMS";
import { BulkSMSCountryCode } from "fp/store/dto/sms/SendSMSDTO";

export interface SendSMSProps {}

interface SendSMSViewState {
  souceType: SendSMSSouceType;
  file: FileUploadModel | null;
}

interface SendSMSFormData {
  source: string;
  template: string;
  senderName: string;
  forceDND: number;
  listIds: string;
  countryCode: number;
  messageType: number;
}

function filterOption(input: string, option?: { label: string }): boolean {
  if (!option) {
    return false;
  }
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export function SendSMS(props: SendSMSProps): ReactNode {
  const [state, setState] = useState<SendSMSViewState>({ souceType: SendSMSSouceType.FILE, file: null });
  const [form] = Form.useForm<SendSMSFormData>();
  const fv = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.bulkSMS);

  useEffect(() => {
    form.validateFields().then(
      (v) => setSubmittable(true),
      (e) => setSubmittable(false)
    );
  }, [fv]);

  useEffect(() => {
    dispatch(bulkSMSCheckBalance());
  }, []);

  return (
    <Card
      title="发送短信"
      extra={
        <Spin spinning={s.loading.balance}>
          <Space align="end">
            <MoneyCollectOutlined />
            <Typography.Text>余额</Typography.Text>
            <Typography.Text strong>{s.balance.amount}</Typography.Text>
            <Typography.Text>Units</Typography.Text>
          </Space>
        </Spin>
      }
    >
      <Form<SendSMSFormData>
        form={form}
        labelCol={{ span: 4 }}
        initialValues={{}}
        autoComplete="off"
        onFinish={(v) => {
          dispatch(
            bulkSMSSend({
              sourceType: state.souceType,
              source: state.souceType === SendSMSSouceType.FILE && state.file !== null ? state.file.name : v.source,
              template: v.template,
              senderName: v.senderName,
              forceDND: v.forceDND,
              listIds: v.listIds,
              countryCode: v.countryCode,
              messageType: v.messageType,
            })
          );
        }}
      >
        <Form.Item<SendSMSFormData> label="数据类型" required>
          <Radio.Group value={state.souceType} onChange={(e) => setState({ ...state, souceType: e.target.value })}>
            <Radio.Button value={SendSMSSouceType.FILE}>文件</Radio.Button>
            <Radio.Button value={SendSMSSouceType.TEXT}>文本</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item<SendSMSFormData> label="国家" required name="countryCode">
          <Select
            showSearch
            filterOption={filterOption}
            options={BulkSMSCountryCode.map((r) => ({ label: r.name, value: r.code }))}
          />
        </Form.Item>
        <Form.Item<SendSMSFormData> label="发送人名称" required name="senderName">
          <Input />
        </Form.Item>

        <Form.Item<SendSMSFormData> label="短信模版" name="template" required>
          <Input.TextArea rows={4} placeholder="Some text here {{code}}" maxLength={512} showCount />
        </Form.Item>

        {state.souceType === SendSMSSouceType.TEXT ? (
          <Form.Item<SendSMSFormData> label="电话号码" name="source" required>
            <Input.TextArea
              showCount
              rows={4}
              placeholder={"11223344\n55667788\n99000000"}
            />
          </Form.Item>
        ) : (
          <Form.Item<SendSMSFormData> label="电话号码文件" name="source">
            <Upload.Dragger
              withCredentials
              name="file"
              action={`${HttpClient.GetBaseURI()}/v1/file/upload`}
              onChange={(info) => {
                const { status } = info.file;
                if (status !== "uploading") {
                  console.log(info.file, info.fileList);
                }

                if (status === "done") {
                  const entity: FileUploadModel = info.file.response.data;
                  setState({ ...state, file: entity });
                } else if (status === "error") {
                  setState({ ...state, file: null });
                }
              }}
              onDrop={(e) => {
                console.log("Dropped files", e.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-hint">点击或拖动文件到此处上传，只支持单个文件上传，多次上传以最后一个为准</p>
              <p className="ant-upload-hint">文件内容为电话号码列表，一个号码单独一行</p>
            </Upload.Dragger>
          </Form.Item>
        )}
        <Form.Item<SendSMSFormData> label="消息类型" name="messageType" required>
          <Select>
            <Select.Option value={1}>Standard SMS</Select.Option>
            <Select.Option value={2}>Flash SMS (TODO)</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item<SendSMSFormData> wrapperCol={{ offset: 4 }} name="forceDND">
          <Checkbox
            value={1}
            onChange={(e) => {
              form.setFieldValue("forceDND", e.target.checked ? 1 : 0);
            }}
          >
            优先线路: 覆盖DND，更多信息见
            <Button type="link" target="_blank" href="https://www.bulksmslive.com/network-status">
              网络状态
            </Button>
          </Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit" disabled={!submittable || s.loading.send} loading={s.loading.send}>
            发送
          </Button>
        </Form.Item>
      </Form>
      {s.model ? <Alert message={BulkSMSStatus.resolve(s.model.status)?.message()} /> : null}
    </Card>
  );
}
