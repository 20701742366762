// 金融机构名称	金融机构代码		变体一	变体二
// 9 Payment Service Bank,120001,,9 Payment Servic,9 Payment,,,,,,,,,,,,,
// ACCESS BANK PLC,14,,ACCESS,ACCESS bank,,,,,,,,,,,,,
// "ECOBANK NIGERIA PLC	",10,,eco,eco bank,,,,,,,,,,,,,
// FIDELITY BANK PLC,7,,FIDELITY,fidelity bank,,,,,,,,,,,,,
// FIRST BANK OF NIGERIA PLC,16,,FIRST BANK,first,,,,,,,,,,,,,
// FIRST CITY MONUMENT BANK,3,,fcmb,,,,,,,,,,,,,,
// Gashua Microfinance Bank,90168,,,,,,,,,,,,,,,,
// Gateway Mortgage Bank,70009,,,,,,,,,,,,,,,,
// Girei Microfinance Bank,90186,,,,,,,,,,,,,,,,
// Globus Bank,27,,,,,,,,,,,,,,,,
// GUARANTY TRUST BANK PLC,13,,gtb,gt bank,,,,,,,,,,,,,
// HERITAGE BANK,20,,heritage,,,,,,,,,,,,,,
// Nuture MFB,90364,,nuture,,,,,,,,,,,,,,
// KEYSTONE BANK PLC,2,,KEYSTONE,keystone bank,,,,,,,,,,,,,
// Kuda Microfinance Bank,90267,,Kuda,,,,,,,,,,,,,,
// OPay,100004,,opay,,,,,,,,,,,,,,
// Paga,100002,,paga,,,,,,,,,,,,,,
// PalmPay,100033,,palmpay,plampay,,,,,,,,,,,,,
// POLARIS BANK,8,,polaris,,,,,,,,,,,,,,
// Prestige Microfinance bank,90274,,Prestige,,,,,,,,,,,,,,
// Providus Bank,23,,Providus,pro,,,,,,,,,,,,,
// STANBIC IBTC BANK PLC,12,,stanbic,stanbic bank,,,,,,,,,,,,,
// STERLING BANK PLC,1,,sterling bank,sterling,,,,,,,,,,,,,
// Uniben Microfinance bank,90266,,Uniben,,,,,,,,,,,,,,
// UNION BANK OF NIGERIA PLC,18,,UNION,,,,,,,,,,,,,,
// UNITED BANK FOR AFRICA PLC,4,,UNITED,uba,,,,,,,,,,,,,
// UNITY BANK PLC,11,,UNITY,,,,,,,,,,,,,,
// VFD MICROFINANCE BANK,90110,,vfd,,,,,,,,,,,,,,
// WEMA BANK PLC,17,,WEMA,WEMA BANK,,,,,,,,,,,,,
// ZENITH BANK PLC,15,,ZENITH,ZENITH BANK,,,,,,,,,,,,,

// [金融机构名称, 金融机构代码, [变体1,变体2]]
export type BankAlias = [string, number, string[]];

export const BankAliasData: BankAlias[] = [
  ["9 Payment Service Bank", 120001, ["9 Payment Servic", "9 Payment"]],
  ["ACCESS BANK PLC", 14, ["ACCESS", "ACCESS bank"]],
  ["ECOBANK NIGERIA PLC", 10, ["eco", "eco bank"]],
  ["FIDELITY BANK PLC", 7, ["FIDELITY", "fidelity bank"]],
  ["FIRST BANK OF NIGERIA PLC", 16, ["FIRST BANK", "first"]],
  ["FIRST CITY MONUMENT BANK", 3, ["fcmb"]],
  ["GUARANTY TRUST BANK PLC", 13, ["gtb", "gt bank"]],
  ["HERITAGE BANK", 20, ["heritage"]],
  ["Nuture MFB", 90364, ["nuture"]],
  ["KEYSTONE BANK PLC", 2, ["KEYSTONE", "keystone bank"]],
  ["Kuda Microfinance Bank", 90267, ["Kuda"]],
  ["OPay", 100004, ["opay"]],
  ["Paga", 100002, ["paga"]],
  ["PalmPay", 100033, ["palmpay", "plampay"]],
  ["POLARIS BANK", 8, ["polaris"]],
  ["Prestige Microfinance bank", 90274, ["Prestige"]],
  ["Providus Bank", 23, ["Providus", "pro"]],
  ["STANBIC IBTC BANK PLC", 12, ["stanbic", "stanbic bank"]],
  ["STERLING BANK PLC", 1, ["sterling bank", "sterling"]],
  ["Uniben Microfinance bank", 90266, ["Uniben"]],
  ["UNION BANK OF NIGERIA PLC", 18, ["UNION"]],
  ["UNITED BANK FOR AFRICA PLC", 4, ["UNITED", "uba"]],
  ["UNITY BANK PLC", 11, ["UNITY"]],
  ["VFD MICROFINANCE BANK", 90110, ["vfd"]],
  ["WEMA BANK PLC", 17, ["WEMA", "WEMA BANK"]],
  ["ZENITH BANK PLC", 15, ["ZENITH", "ZENITH BANK"]],
];
