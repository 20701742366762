import { useAppSelector } from "fp/store/hooks";
import { UserRole, UserRoleName } from "fp/store/type";
import { FunctionComponent, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { Card, Typography } from "antd";

export interface AuthorizationProps {
  children: ReactNode;
  role: UserRole;
}

const UserRoleValue = {
  [UserRoleName.STAFF]: UserRole.STAFF,
  [UserRoleName.MANAGER]: UserRole.MANAGER,
  [UserRoleName.ADMIN]: UserRole.ADMIN,
};

export function checkUserRole(role: UserRole, userRole: UserRoleName) {
  const a: number = UserRoleValue[userRole];
  return a >= role;
}

export const Authorization: FunctionComponent<AuthorizationProps> = function (props: AuthorizationProps): ReactNode {
  const user = useAppSelector((state) => state.sign.user);

  if (user.id === -1) {
    return <Navigate to="/login" />;
  }

  if (checkUserRole(props.role, user.role)) {
    return props.children;
  }

  return (
    <Card>
      <Typography.Text type="danger">无权限访问</Typography.Text>;
    </Card>
  );
};
