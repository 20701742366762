import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Button, Card, ConfigProvider, Form, Input, Modal, Space, Table, Tag, Typography, theme } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import { SecurityCodeStatus } from "fp/store/type";
import { securityCodeCreate, securityCodeNullify, securityCodePagination } from "fp/store/slice/securityCode";
import dayjs from "dayjs";

export interface SecurityCodeProps {}

interface SecurityCodeViewState {
  visible: boolean;
}

type FieldType = {
  account?: string;
};

const SecurityCodeStatusNameMap = {
  [SecurityCodeStatus.NORMAL]: "正常",
  [SecurityCodeStatus.NULLIFY]: "作废",
  [SecurityCodeStatus.EXPIRED]: "过期",
};

const SecurityCodeStatusColorMap = {
  [SecurityCodeStatus.NORMAL]: "default",
  [SecurityCodeStatus.NULLIFY]: "gold",
  [SecurityCodeStatus.EXPIRED]: "warning",
};

export const SecurityCode: FunctionComponent<SecurityCodeProps> = function (props: SecurityCodeProps): ReactNode {
  const { token } = theme.useToken();
  const [state, setState] = useState<SecurityCodeViewState>({ visible: false });
  const pagination = useAppSelector((state) => state.securityCode.pagination.data);
  const loading = useAppSelector((state) => state.securityCode.loading);
  const model = useAppSelector((state) => state.securityCode.model);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      securityCodePagination({
        current: pagination.current,
        size: pagination.size,
      })
    );
  }, []);

  return (
    <Card
      title={
        <>
          <Button size="small" type="primary" onClick={() => setState({ visible: true })}>
            新增
          </Button>
        </>
      }
    >
      {state.visible ? (
        <Modal
          title="新增安全码"
          open={state.visible}
          onCancel={() => setState({ visible: false })}
          onOk={() => setState({ visible: false })}
        >
          <Card>
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={(v: Required<FieldType>) => dispatch(securityCodeCreate(v))}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label="用户名"
                name="account"
                rules={[{ required: true, message: "请输入用户名！" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button type="primary" htmlType="submit" loading={loading.create}>
                  新增
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Modal>
      ) : null}
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowSelectedBg: token.colorPrimaryBgHover,
            },
          },
        }}
      >
        <Table
          bordered
          size="small"
          rowKey="id"
          rowSelection={{ selectedRowKeys: model === null ? [] : [model.id], type: "radio" }}
          loading={loading.pagination}
          dataSource={pagination.data}
          pagination={{
            total: pagination.total,
            pageSize: pagination.size,
            current: pagination.current,
            onChange(page, pageSize) {
              dispatch(securityCodePagination({ current: page, size: pageSize }));
            },
          }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              align: "center",
              width: "20%",
            },
            {
              title: "用户名",
              dataIndex: "account",
              align: "center",
              width: "20%",
            },
            {
              title: "安全码",
              dataIndex: "code",
              align: "center",
              width: "20%",
              render(value, record, index) {
                return <Typography.Text copyable>{value}</Typography.Text>;
              },
            },
            {
              title: "创建时间",
              dataIndex: "createTime",
              align: "center",
              render(value, record, index) {
                return dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "状态",
              dataIndex: "status",
              align: "center",
              width: "20%",
              render(value, record, index) {
                return (
                  <Space>
                    <Tag color={SecurityCodeStatusColorMap[record.status]}>
                      {SecurityCodeStatusNameMap[record.status] || "-"}
                    </Tag>
                    {record.status === SecurityCodeStatus.NORMAL ? (
                      <Button
                        loading={loading.nullifyId === record.id}
                        size="small"
                        type="dashed"
                        danger
                        onClick={() => dispatch(securityCodeNullify({ id: record.id }))}
                      >
                        作废
                      </Button>
                    ) : null}
                  </Space>
                );
              },
            },
          ]}
        />
      </ConfigProvider>
    </Card>
  );
};
