import { ReactNode, useEffect, useState } from "react";
import { HttpClient } from "fp/store/HttpClient";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Progress, Space, Table, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import { bulkSMSRecord, bulkSMSResend } from "fp/store/slice/bulkSMS";
import { SendSMSSouceType, SendSMSSouceTypeName } from "fp/store/type";
import { BulkSMSStatus } from "./BulkSMSStatus";
import dayjs from "dayjs";

export interface SendSMSRecordProps {}

interface SendSMSRecordViewState {}

export function SendSMSRecord(props: SendSMSRecordProps): ReactNode {
  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.bulkSMS);
  const {
    loading,
    record: { data: pagination },
  } = s;

  useEffect(() => {
    dispatch(bulkSMSRecord({ current: pagination.current, size: pagination.size, query: {} }));
  }, []);

  return (
    <Card title="已发短信记录">
      <Table
        bordered
        size="small"
        rowKey="id"
        dataSource={pagination.data}
        loading={loading.record}
        pagination={{
          current: pagination.current,
          pageSize: pagination.size,
          total: pagination.total,
          onChange(page, pageSize) {
            dispatch(bulkSMSRecord({ current: page, size: pageSize, query: {} }));
          },
        }}
        columns={[
          { title: "ID", dataIndex: "id", align: "center", width: "10%" },
          {
            title: "数据类型",
            dataIndex: "sourceType",
            align: "center",
            width: "5%",
            render(value, record, index) {
              return record.sourceType === SendSMSSouceTypeName.TEXT ? "文本" : "文件";
            },
          },
          {
            title: "短信内容",
            dataIndex: "source",
            align: "center",
            width: "10%",
            render(value, record, index) {
              return (
                <Space>
                  <Button
                    download
                    size="small"
                    icon={<DownloadOutlined />}
                    href={`${HttpClient.GetBaseURI()}/v1/file/download/${record.source}`}
                  />

                  <Button type="link" href={`${HttpClient.GetBaseURI()}/v1/file/read/${record.source}`}>
                    <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: { title: "点击查看接收人列表" } }}>
                      {record.source}
                    </Typography.Text>
                  </Button>
                </Space>
              );
            },
          },
          {
            title: "状态",
            dataIndex: "status",
            align: "center",
            render(value, record, index) {
              return BulkSMSStatus.resolve(record.status).message();
            },
          },
          {
            title: "处理进度",
            dataIndex: "complatedTotal",
            align: "center",
            width: "10%",
            render(value, record, index) {
              const v =
                record.recipientsTotal < 1 ? 100 : Math.floor((record.sendedTotal / record.recipientsTotal) * 100);
              return v + "%";
              // return <Progress percent={Math.floor((record.sendedTotal / record.recipientsTotal) * 100)} />;
            },
          },
          {
            title: "总数",
            dataIndex: "recipientsTotal",
            align: "center",
          },
          {
            title: "失败",
            dataIndex: "sendedTotal",
            align: "center",
            render(value, record, index) {
              return record.sendedTotal - record.complatedTotal;
            },
          },
          {
            title: "成功",
            dataIndex: "complatedTotal",
            align: "center",
          },
          {
            title: "费用",
            dataIndex: "units",
            align: "center",
          },
          {
            title: "当前余额",
            dataIndex: "balance",
            align: "center",
          },
          {
            title: "绑定余额",
            dataIndex: "bundleBalance",
            align: "center",
          },
          {
            title: "时间",
            dataIndex: "createTime",
            align: "center",
            width: "10%",
            render(value, record, index) {
              return dayjs(record.createTime).format("YYYY-MM-DD HH:mm");
            },
          },
          {
            title: "操作",
            dataIndex: "id",
            align: "center",
            width: "5%",
            render(value, record, index) {
              return (
                <Button
                  size="small"
                  type="primary"
                  style={{ padding: 2 }}
                  onClick={() => dispatch(bulkSMSResend(record.id))}
                  disabled={loading.resend}
                  loading={loading.resend}
                >
                  再次发送
                </Button>
              );
            },
          },
        ]}
      />
    </Card>
  );
}
