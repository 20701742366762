import React, { FC, ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import {
  groupAccountBookCreate,
  groupAccountBookItemGroups,
  groupAccountBookPaginationByGroup,
  groupAccountBookPaginationAll,
  groupAccountBookPaginationPaymentOrderByGroupId,
  setPage,
} from "fp/store/slice/groupAccountBook";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  TimePicker,
} from "antd";
import { FMPayGroupAccountBookModel, FMPayGroupAccountBookType, FMPayGroupModel } from "fp/store/type";
import dayjs, { Dayjs } from "dayjs";
import { findGroupByIdOrAliasId, GroupSelector } from "fp/components/GroupSelector";
import Decimal from "decimal.js";

export interface GroupAccountBookProps {}

interface GroupAccountBookViewState {
  selectedGroup: FMPayGroupModel | null;
  openAddGroupAccoutBookModel: boolean;
  since: Dayjs;
  until: Dayjs;
  pagination: {
    current: number;
    size: number;
    total: number;
    data: FMPayGroupAccountBookModel[];
  };
  a: string;
  b: string;
  c: string;
}

interface AddGroupAccountBookFormData {
  groupId: string;
  amount: string;
}

function getOnPaginationChangeState(
  page: number,
  pageSize: number,
  data: FMPayGroupAccountBookModel[]
): GroupAccountBookViewState["pagination"] {
  const start = (page - 1) * pageSize;
  return {
    current: page,
    size: pageSize,
    total: data.length,
    data: data.slice(start, start + pageSize),
  };
}

export const GroupAccountBook: FC<GroupAccountBookProps> = function (props: GroupAccountBookProps): ReactNode {
  const [state, setState] = useState<GroupAccountBookViewState>({
    selectedGroup: null,
    openAddGroupAccoutBookModel: false,
    since: dayjs().startOf("day"),
    until: dayjs().endOf("day"),
    pagination: {
      current: 1,
      size: 10,
      total: 0,
      data: [],
    },
    a: "",
    b: "",
    c: "",
  });
  const dispatch = useAppDispatch();
  const groupAccountBooks = useAppSelector((state) => state.groupAccountBook);
  const {
    orders,
    groups,
    accountBooks: { data: pagination },
    model,
    loading,
  } = groupAccountBooks;

  const [form] = Form.useForm<AddGroupAccountBookFormData>();
  const fv = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const updateDatasource = () => {
    if (state.selectedGroup === null) {
      return;
    }

    dispatch(
      groupAccountBookPaginationByGroup({
        current: pagination.current,
        size: pagination.size,
        since: state.since.valueOf(),
        until: state.until.valueOf(),
        query: {
          groupId: state.selectedGroup.id,
        },
      })
    );
    dispatch(
      groupAccountBookPaginationPaymentOrderByGroupId({
        current: pagination.current,
        size: pagination.size,
        since: state.since.valueOf(),
        until: state.until.valueOf(),
        query: {
          groupId: state.selectedGroup.id,
        },
      })
    );
  };

  const onPaginationChange = (page: number, pageSize: number, data: FMPayGroupAccountBookModel[]) => {
    const start = (page - 1) * pageSize;
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        current: page,
        size: pageSize,
        total: data.length,
        data: data.slice(start, start + pageSize),
      },
    });
  };

  useEffect(() => updateDatasource(), [state.pagination.current, state.pagination.size, model]);

  useEffect(() => {
    const a = orders.reduce((a, b) => a.plus(new Decimal(b.amount)), new Decimal(0));
    const b = pagination.data.reduce((a, b) => a.plus(new Decimal(b.amount)), new Decimal(0));
    const c = a.sub(b);
    setState({
      ...state,
      pagination: getOnPaginationChangeState(state.pagination.current, state.pagination.size, pagination.data),
      a: a.div(100).toString(),
      b: b.div(100).toString(),
      c: c.div(100).toString(),
    });
  }, [orders, pagination.data]);

  useEffect(
    () =>
      setState({
        ...state,
        pagination: getOnPaginationChangeState(state.pagination.current, state.pagination.size, pagination.data),
      }),
    [state.pagination.current, state.pagination.size]
  );

  useEffect(() => {
    form.validateFields().then(
      (v) => setSubmittable(true),
      (e) => setSubmittable(false)
    );
  }, [fv]);

  useEffect(() => {
    dispatch(groupAccountBookItemGroups());
  }, []);

  return (
    <Card
      title="群组账本"
      extra={
        <Row gutter={12} justify="space-between">
          <Col>
            <DatePicker.RangePicker
              value={[state.since, state.until]}
              onChange={(d) => {
                if (d === null) {
                  return;
                }
                const [since, until] = d;
                if (since === null || until === null) {
                  return;
                }
                setState({ ...state, since, until });
              }}
            />
          </Col>
          <Col>
            <Space>
              <GroupSelector data={groups} onChange={(m) => setState({ ...state, selectedGroup: m })} />
              <Button
                type="primary"
                disabled={state.selectedGroup === null}
                loading={loading.accountBooks}
                onClick={() => updateDatasource()}
              >
                查询
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Modal
        width="80%"
        title="群组结算"
        open={state.openAddGroupAccoutBookModel}
        onCancel={() => setState({ ...state, openAddGroupAccoutBookModel: false })}
        onOk={() => setState({ ...state, openAddGroupAccoutBookModel: false })}
      >
        <Card>
          <Form<AddGroupAccountBookFormData>
            form={form}
            labelCol={{ span: 10 }}
            style={{ maxWidth: 220, minWidth: 120 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={(v) => {
              dispatch(groupAccountBookCreate(v));
            }}
          >
            <Form.Item<AddGroupAccountBookFormData> label="群组" name="groupId" required>
              <GroupSelector data={groups} onChange={(m) => form.setFieldValue("groupId", m.id)} />
            </Form.Item>

            <Form.Item<AddGroupAccountBookFormData> label="金额" name="amount" required>
              <InputNumber
                onChange={(v) => {
                  if (v === null) {
                    return;
                  }
                  form.setFieldValue("amount", v.toString());
                }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 10 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading.create}
                disabled={loading.create || !submittable}
              >
                新建
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <Table<FMPayGroupAccountBookModel>
        title={() => (
          <Row justify="space-between">
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  setState({
                    ...state,
                    openAddGroupAccoutBookModel: true,
                  })
                }
              >
                结算
              </Button>
            </Col>
            <Col>
              <Space>
                <Tag color="processing">应收:{state.a}</Tag>
                <Tag color="success">已结:{state.b}</Tag>
                <Tag color="error">未结:{state.c}</Tag>
              </Space>
            </Col>
          </Row>
        )}
        bordered
        rowKey="id"
        size="small"
        dataSource={state.pagination.data}
        pagination={{
          pageSize: state.pagination.size,
          current: state.pagination.current,
          total: state.pagination.total,
          showSizeChanger: true,
          onChange(page, pageSize) {
            setState({
              ...state,
              pagination: {
                ...state.pagination,
                current: page,
                size: pageSize,
              },
            });
          },
        }}
        columns={[
          { title: "ID", dataIndex: "id", align: "center", width: "20%" },
          {
            title: "群组",
            dataIndex: "groupId",
            align: "center",
            width: "20%",
            render(value, record, index) {
              const d = findGroupByIdOrAliasId(groups, record.groupId);
              return d ? d.name : "-";
            },
          },
          {
            title: "金额",
            dataIndex: "amount",
            align: "center",
            width: "10%",
            render(value, record, index) {
              return new Decimal(record.amount).div(100).toString();
            },
          },
          {
            title: "类型",
            dataIndex: "type",
            align: "center",
            width: "20%",
            render(value, record, index) {
              return record.type === FMPayGroupAccountBookType.TAKE_IN
                ? "收入"
                : record.type === FMPayGroupAccountBookType.TAKE_OUT
                ? "支出"
                : "-";
            },
          },
          {
            title: "创建时间",
            dataIndex: "create_time",
            align: "center",
            render(value, record, index) {
              return dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss");
            },
          },
        ]}
      />
    </Card>
  );
};
