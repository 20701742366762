import React, { FC, ReactNode, useEffect, useState } from "react";
import { Select, Space, Tag, Typography } from "antd";
import { FMPayGroupModel } from "fp/store/type";

export interface GroupSelectorProps {
  data: FMPayGroupModel[];
  onChange: (model: FMPayGroupModel) => void;
}

interface GroupSelectorOptionData {
  id: string;
  name: string;
  parentId: string | null;
  parentName: string | null;
}

interface GroupSelectorState {
  data: GroupSelectorOptionData[];
  options: { label: string; value: string; parentName: string | null }[];
  value: string;
}

export function findGroupByIdOrAliasId(groups: FMPayGroupModel[], id: string): FMPayGroupModel | null {
  const d = groups.find((r) => r.id === id || r.alias.find((j) => j.id === id) !== void 0);
  return d || null;
}

function filterOption(input: string, option?: { label: string }): boolean {
  if (!option) {
    return false;
  }
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export const GroupSelector: FC<GroupSelectorProps> = function (props: GroupSelectorProps): ReactNode {
  const [state, setState] = useState<GroupSelectorState>({
    data: [],
    options: [],
    value: "",
  });

  useEffect(() => {
    const g: GroupSelectorOptionData[] = [];
    for (const d of props.data) {
      g.push({ id: d.id, name: d.name, parentId: null, parentName: null });

      for (const a of d.alias) {
        g.push({ id: a.id, name: a.name, parentId: d.id, parentName: d.name });
      }
    }

    setState({
      ...state,
      data: g,
      options: g.map((r) => ({
        label: r.name,
        value: r.id,
        parentName: r.parentName,
      })),
    });
  }, [props.data]);

  return (
    <Select<string, { label: string; value: string; parentName: string | null }>
      showSearch
      style={{ minWidth: 120 }}
      filterOption={filterOption}
      value={state.value}
      options={state.options}
      optionRender={(o, info) => {
        return (
          <Space key={info.index}>
            <Typography.Text>{o.data.label}</Typography.Text>
            {o.data.parentName ? <Tag>{o.data.parentName}</Tag> : null}
          </Space>
        );
      }}
      onChange={(v) => {
        const g = findGroupByIdOrAliasId(props.data, v);
        if (g) {
          props.onChange(g);
        }
        setState({ ...state, value: v });
      }}
    />
  );
};
