import { SendSMSSouceType } from "fp/store/type";

export interface SendSMSDTO {
  source: string;
  sourceType: number;
  template: string;

  senderName: string;
  forceDND: number;
  listIds: string;
  countryCode: number;
  messageType: number;
}


export const BulkSMSCountryCode: { name: string; code: number }[] = [
  {
    name: "(93) Afghanistan",
    code: 1,
  },
  {
    name: "(355) Albania",
    code: 2,
  },
  {
    name: "(213) Algeria",
    code: 3,
  },
  {
    name: "(1684) American Samoa",
    code: 4,
  },
  {
    name: "(376) Andorra",
    code: 5,
  },
  {
    name: "(244) Angola",
    code: 6,
  },
  {
    name: "(1264) Anguilla",
    code: 7,
  },
  {
    name: "(672) Antarctica",
    code: 8,
  },
  {
    name: "(1268) Antigua",
    code: 9,
  },
  {
    name: "(54) Argentina",
    code: 10,
  },
  {
    name: "(374) Armenia",
    code: 11,
  },
  {
    name: "(297) Aruba",
    code: 12,
  },
  {
    name: "(247) Ascension",
    code: 13,
  },
  {
    name: "(61) Australia",
    code: 14,
  },
  {
    name: "(672) Australian External Territories",
    code: 15,
  },
  {
    name: "(43) Austria",
    code: 16,
  },
  {
    name: "(994) Azerbaijan",
    code: 17,
  },
  {
    name: "(1242) Bahamas",
    code: 18,
  },
  {
    name: "(973) Bahrain",
    code: 19,
  },
  {
    name: "(880) Bangladesh",
    code: 20,
  },
  {
    name: "(1246) Barbados",
    code: 21,
  },
  {
    name: "(1268) Barbuda",
    code: 22,
  },
  {
    name: "(375) Belarus",
    code: 23,
  },
  {
    name: "(32) Belgium",
    code: 24,
  },
  {
    name: "(501) Belize",
    code: 25,
  },
  {
    name: "(229) Benin",
    code: 26,
  },
  {
    name: "(1441) Bermuda",
    code: 27,
  },
  {
    name: "(975) Bhutan",
    code: 28,
  },
  {
    name: "(591) Bolivia",
    code: 29,
  },
  {
    name: "(387) Bosnia & Herzegovina",
    code: 30,
  },
  {
    name: "(267) Botswana",
    code: 31,
  },
  {
    name: "(55) Brazil",
    code: 32,
  },
  {
    name: "(1284) British Virgin Islands",
    code: 33,
  },
  {
    name: "(673) Brunei Darussalam",
    code: 34,
  },
  {
    name: "(359) Bulgaria",
    code: 35,
  },
  {
    name: "(226) Burkina Faso",
    code: 36,
  },
  {
    name: "(257) Burundi",
    code: 37,
  },
  {
    name: "(855) Cambodia",
    code: 38,
  },
  {
    name: "(237) Cameroon",
    code: 39,
  },
  {
    name: "(1) Canada",
    code: 40,
  },
  {
    name: "(238) Cape Verde Islands",
    code: 41,
  },
  {
    name: "(1345) Cayman Islands",
    code: 42,
  },
  {
    name: "(236) Central African Republic",
    code: 43,
  },
  {
    name: "(235) Chad",
    code: 44,
  },
  {
    name: "(64) Chatham Island (New Zealand)",
    code: 45,
  },
  {
    name: "(56) Chile",
    code: 46,
  },
  {
    name: "(86) China (PRC)",
    code: 47,
  },
  {
    name: "(61) Christmas Island",
    code: 48,
  },
  {
    name: "(61) CocosKeeling Islands",
    code: 49,
  },
  {
    name: "(57) Colombia",
    code: 50,
  },
  {
    name: "(269) Comoros",
    code: 51,
  },
  {
    name: "(242) Congo",
    code: 52,
  },
  {
    name: "(243) Congo, Dem. Rep. of (Zaire)",
    code: 53,
  },
  {
    name: "(682) Cook Islands",
    code: 54,
  },
  {
    name: "(506) Costa Rica",
    code: 55,
  },
  {
    name: "(225) Cote d'Ivoire (Ivory Coast)",
    code: 56,
  },
  {
    name: "(385) Croatia",
    code: 57,
  },
  {
    name: "(53) Cuba",
    code: 58,
  },
  {
    name: "(5399) Cuba (Guantanamo Bay)",
    code: 59,
  },
  {
    name: "(599) Curacao",
    code: 60,
  },
  {
    name: "(357) Cyprus",
    code: 61,
  },
  {
    name: "(420) Czech Republic",
    code: 62,
  },
  {
    name: "(45) Denmark",
    code: 63,
  },
  {
    name: "(246) Diego Garcia",
    code: 64,
  },
  {
    name: "(253) Djibouti",
    code: 65,
  },
  {
    name: "(1767) Dominica",
    code: 66,
  },
  {
    name: "(1809) Dominican Republic",
    code: 67,
  },
  {
    name: "(670) East Timor",
    code: 68,
  },
  {
    name: "(56) Easter Island",
    code: 69,
  },
  {
    name: "(593) Ecuador",
    code: 70,
  },
  {
    name: "(20) Egypt",
    code: 71,
  },
  {
    name: "(503) El Salvador",
    code: 72,
  },
  {
    name: "(17625) Ellipso (Mobile Satellite service)",
    code: 73,
  },
  {
    name: "(88213) EMSAT (Mobile Satellite service)",
    code: 74,
  },
  {
    name: "(240) Equatorial Guinea",
    code: 75,
  },
  {
    name: "(291) Eritrea",
    code: 76,
  },
  {
    name: "(372) Estonia",
    code: 77,
  },
  {
    name: "(251) Ethiopia",
    code: 78,
  },
  {
    name: "(500) Falkland Islands (Malvinas)",
    code: 79,
  },
  {
    name: "(298) Faroe Islands",
    code: 80,
  },
  {
    name: "(679) Fiji Islands",
    code: 81,
  },
  {
    name: "(358) Finland",
    code: 82,
  },
  {
    name: "(33) France",
    code: 83,
  },
  {
    name: "(596) French Antilles",
    code: 84,
  },
  {
    name: "(594) French Guiana",
    code: 85,
  },
  {
    name: "(689) French Polynesia",
    code: 86,
  },
  {
    name: "(241) Gabonese Republic",
    code: 87,
  },
  {
    name: "(220) Gambia",
    code: 88,
  },
  {
    name: "(995) Georgia",
    code: 89,
  },
  {
    name: "(49) Germany",
    code: 90,
  },
  {
    name: "(233) Ghana",
    code: 91,
  },
  {
    name: "(350) Gibraltar",
    code: 92,
  },
  {
    name: "(881) Global Mobile Satellite System (GMSS)",
    code: 93,
  },
  {
    name: "(8818) Globalstar",
    code: 94,
  },
  {
    name: "(17637) Globalstar (Mobile Satellite Service)",
    code: 95,
  },
  {
    name: "(30) Greece",
    code: 96,
  },
  {
    name: "(299) Greenland",
    code: 97,
  },
  {
    name: "(1473) Grenada",
    code: 98,
  },
  {
    name: "(590) Guadeloupe",
    code: 99,
  },
  {
    name: "(1671) Guam",
    code: 100,
  },
  {
    name: "(5399) Guantanamo Bay",
    code: 101,
  },
  {
    name: "(502) Guatemala",
    code: 102,
  },
  {
    name: "(245) GuineaBissau",
    code: 103,
  },
  {
    name: "(224) Guinea",
    code: 104,
  },
  {
    name: "(592) Guyana",
    code: 105,
  },
  {
    name: "(509) Haiti",
    code: 106,
  },
  {
    name: "(504) Honduras",
    code: 107,
  },
  {
    name: "(852) Hong Kong",
    code: 108,
  },
  {
    name: "(36) Hungary",
    code: 109,
  },
  {
    name: "(17621) ICO Global (Mobile Satellite Service)",
    code: 110,
  },
  {
    name: "(354) Iceland",
    code: 111,
  },
  {
    name: "(91) India",
    code: 112,
  },
  {
    name: "(62) Indonesia",
    code: 113,
  },
  {
    name: "(871) Inmarsat (Atlantic Ocean East)",
    code: 114,
  },
  {
    name: "(874) Inmarsat (Atlantic Ocean West)",
    code: 115,
  },
  {
    name: "(873) Inmarsat (Indian Ocean)",
    code: 116,
  },
  {
    name: "(872) Inmarsat (Pacific Ocean)",
    code: 117,
  },
  {
    name: "(800) International Freephone Service",
    code: 118,
  },
  {
    name: "(808) Intl. Shared Cost Service (ISCS)",
    code: 119,
  },
  {
    name: "(98) Iran",
    code: 120,
  },
  {
    name: "(964) Iraq",
    code: 121,
  },
  {
    name: "(353) Ireland",
    code: 122,
  },
  {
    name: "(17633) Iridium (Mobile Satellite service)",
    code: 123,
  },
  {
    name: "(972) Israel",
    code: 124,
  },
  {
    name: "(39) Italy",
    code: 125,
  },
  {
    name: "(1876) Jamaica",
    code: 126,
  },
  {
    name: "(81) Japan",
    code: 127,
  },
  {
    name: "(962) Jordan",
    code: 128,
  },
  {
    name: "(7) Kazakhstan",
    code: 129,
  },
  {
    name: "(254) Kenya",
    code: 130,
  },
  {
    name: "(686) Kiribati",
    code: 131,
  },
  {
    name: "(850) Korea (North)",
    code: 132,
  },
  {
    name: "(82) Korea (South)",
    code: 133,
  },
  {
    name: "(381) Kosovo",
    code: 134,
  },
  {
    name: "(965) Kuwait",
    code: 135,
  },
  {
    name: "(996) Kyrgyz Republic",
    code: 136,
  },
  {
    name: "(856) Laos",
    code: 137,
  },
  {
    name: "(371) Latvia",
    code: 138,
  },
  {
    name: "(961) Lebanon",
    code: 139,
  },
  {
    name: "(266) Lesotho",
    code: 140,
  },
  {
    name: "(231) Liberia",
    code: 141,
  },
  {
    name: "(218) Libya",
    code: 142,
  },
  {
    name: "(423) Liechtenstein",
    code: 143,
  },
  {
    name: "(370) Lithuania",
    code: 144,
  },
  {
    name: "(352) Luxembourg",
    code: 145,
  },
  {
    name: "(853) Macao",
    code: 146,
  },
  {
    name: "(389) Macedonia (Former Yugoslav Rep of.)",
    code: 147,
  },
  {
    name: "(261) Madagascar",
    code: 148,
  },
  {
    name: "(265) Malawi",
    code: 149,
  },
  {
    name: "(60) Malaysia",
    code: 150,
  },
  {
    name: "(960) Maldives",
    code: 151,
  },
  {
    name: "(223) Mali Republic",
    code: 152,
  },
  {
    name: "(356) Malta",
    code: 153,
  },
  {
    name: "(692) Marshall Islands",
    code: 154,
  },
  {
    name: "(596) Martinique",
    code: 155,
  },
  {
    name: "(222) Mauritania",
    code: 156,
  },
  {
    name: "(230) Mauritius",
    code: 157,
  },
  {
    name: "(269) Mayotte Island",
    code: 158,
  },
  {
    name: "(52) Mexico",
    code: 159,
  },
  {
    name: "(691) Micronesia",
    code: 160,
  },
  {
    name: "(1808) Midway Island",
    code: 161,
  },
  {
    name: "(373) Moldova",
    code: 162,
  },
  {
    name: "(377) Monaco",
    code: 163,
  },
  {
    name: "(976) Mongolia",
    code: 164,
  },
  {
    name: "(382) Montenegro",
    code: 165,
  },
  {
    name: "(1664) Montserrat",
    code: 166,
  },
  {
    name: "(212) Morocco",
    code: 167,
  },
  {
    name: "(258) Mozambique",
    code: 168,
  },
  {
    name: "(95) Myanmar",
    code: 169,
  },
  {
    name: "(264) Namibia",
    code: 170,
  },
  {
    name: "(674) Nauru",
    code: 171,
  },
  {
    name: "(977) Nepal",
    code: 172,
  },
  {
    name: "(31) Netherlands",
    code: 173,
  },
  {
    name: "(599) Netherlands Antilles",
    code: 174,
  },
  {
    name: "(1869) Nevis",
    code: 175,
  },
  {
    name: "(687) New Caledonia",
    code: 176,
  },
  {
    name: "(64) New Zealand",
    code: 177,
  },
  {
    name: "(505) Nicaragua",
    code: 178,
  },
  {
    name: "(227) Niger",
    code: 179,
  },
  {
    name: "(234) Nigeria",
    code: 180,
  },
  {
    name: "(683) Niue",
    code: 181,
  },
  {
    name: "(672) Norfolk Island",
    code: 182,
  },
  {
    name: "(1670) Northern Marianas Islands",
    code: 183,
  },
  {
    name: "(47) Norway",
    code: 184,
  },
  {
    name: "(968) Oman",
    code: 185,
  },
  {
    name: "(92) Pakistan",
    code: 186,
  },
  {
    name: "(680) Palau",
    code: 187,
  },
  {
    name: "(970) Palestinian Settlements",
    code: 188,
  },
  {
    name: "(507) Panama",
    code: 189,
  },
  {
    name: "(675) Papua New Guinea",
    code: 190,
  },
  {
    name: "(595) Paraguay",
    code: 191,
  },
  {
    name: "(51) Peru",
    code: 192,
  },
  {
    name: "(63) Philippines",
    code: 193,
  },
  {
    name: "(48) Poland",
    code: 194,
  },
  {
    name: "(351) Portugal",
    code: 195,
  },
  {
    name: "(1787) Puerto Rico",
    code: 196,
  },
  {
    name: "(974) Qatar",
    code: 197,
  },
  {
    name: "(262) Reunion Island",
    code: 198,
  },
  {
    name: "(40) Romania",
    code: 199,
  },
  {
    name: "(7) Russia",
    code: 200,
  },
  {
    name: "(250) Rwandese Republic",
    code: 201,
  },
  {
    name: "(290) St. Helena",
    code: 202,
  },
  {
    name: "(1869) St. Kitts/Nevis",
    code: 203,
  },
  {
    name: "(1758) St. Lucia",
    code: 204,
  },
  {
    name: "(508) St. Pierre & Miquelon",
    code: 205,
  },
  {
    name: "(1784) St. Vincent & Grenadines",
    code: 206,
  },
  {
    name: "(685) Samoa",
    code: 207,
  },
  {
    name: "(378) San Marino",
    code: 208,
  },
  {
    name: "(239) Sao Tome and Principe",
    code: 209,
  },
  {
    name: "(966) Saudi Arabia",
    code: 210,
  },
  {
    name: "(221) Senegal",
    code: 211,
  },
  {
    name: "(381) Serbia",
    code: 212,
  },
  {
    name: "(248) Seychelles Republic",
    code: 213,
  },
  {
    name: "(232) Sierra Leone",
    code: 214,
  },
  {
    name: "(65) Singapore",
    code: 215,
  },
  {
    name: "(421) Slovak Republic",
    code: 216,
  },
  {
    name: "(386) Slovenia",
    code: 217,
  },
  {
    name: "(677) Solomon Islands",
    code: 218,
  },
  {
    name: "(252) Somali Democratic Republic",
    code: 219,
  },
  {
    name: "(27) South Africa",
    code: 220,
  },
  {
    name: "(34) Spain",
    code: 221,
  },
  {
    name: "(94) Sri Lanka",
    code: 222,
  },
  {
    name: "(249) Sudan",
    code: 223,
  },
  {
    name: "(597) Suriname",
    code: 224,
  },
  {
    name: "(268) Swaziland",
    code: 225,
  },
  {
    name: "(46) Sweden",
    code: 226,
  },
  {
    name: "(41) Switzerland",
    code: 227,
  },
  {
    name: "(963) Syria",
    code: 228,
  },
  {
    name: "(886) Taiwan",
    code: 229,
  },
  {
    name: "(992) Tajikistan",
    code: 230,
  },
  {
    name: "(255) Tanzania",
    code: 231,
  },
  {
    name: "(66) Thailand",
    code: 232,
  },
  {
    name: "(88216) Thuraya (Mobile Satellite service)",
    code: 233,
  },
  {
    name: "(670) Timor Leste",
    code: 234,
  },
  {
    name: "(228) Togolese Republic",
    code: 235,
  },
  {
    name: "(690) Tokelau",
    code: 236,
  },
  {
    name: "(676) Tonga Islands",
    code: 237,
  },
  {
    name: "(1868) Trinidad & Tobago",
    code: 238,
  },
  {
    name: "(216) Tunisia",
    code: 239,
  },
  {
    name: "(90) Turkey",
    code: 240,
  },
  {
    name: "(993) Turkmenistan",
    code: 241,
  },
  {
    name: "(1649) Turks and Caicos Islands",
    code: 242,
  },
  {
    name: "(688) Tuvalu",
    code: 243,
  },
  {
    name: "(256) Uganda",
    code: 244,
  },
  {
    name: "(380) Ukraine",
    code: 245,
  },
  {
    name: "(971) United Arab Emirates",
    code: 246,
  },
  {
    name: "(44) United Kingdom",
    code: 247,
  },
  {
    name: "(1) United States of America",
    code: 248,
  },
  {
    name: "(1340) US Virgin Islands",
    code: 249,
  },
  {
    name: "(878) Universal Personal Telecomms (UPT)",
    code: 250,
  },
  {
    name: "(598) Uruguay",
    code: 251,
  },
  {
    name: "(998) Uzbekistan",
    code: 252,
  },
  {
    name: "(678) Vanuatu",
    code: 253,
  },
  {
    name: "(39) Vatican City",
    code: 254,
  },
  {
    name: "(58) Venezuela",
    code: 255,
  },
  {
    name: "(84) Vietnam",
    code: 256,
  },
  {
    name: "(808) Wake Island",
    code: 257,
  },
  {
    name: "(681) Wallis and Futuna Islands",
    code: 258,
  },
  {
    name: "(967) Yemen",
    code: 259,
  },
  {
    name: "(260) Zambia",
    code: 260,
  },
  {
    name: "(255) Zanzibar",
    code: 261,
  },
  {
    name: "(263) Zimbabwe",
    code: 262,
  },
];
