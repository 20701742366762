import React, { ReactNode, useEffect } from "react";
import { Button, Card, Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import {
  moniepointAccounts,
  moniepointActive,
  moniepointCurrent,
  moniepointDisable,
} from "fp/store/slice/moniepointTask";
import dayjs from "dayjs";
import { Decimal } from "decimal.js";

export interface MoniepointTaskProps {}
export interface MoniepointTaskViewState {}

function formatNumberWithCommas(s: string): string {
  let [integer, decimal = ""] = s.split(".");

  const doSplit = (n: string, isInteger = true) => {
    if (n === "" || n === void 0) {
      return "";
    }

    let a: string[] = n.split("");

    if (isInteger) {
      a = a.reverse();
    }

    let str: string[] = [];

    for (let i = 0; i < a.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        str.push(",");
      }
      str.push(a[i]);
    }

    if (isInteger) {
      return str.reverse().join("");
    }

    return str.join("");
  };

  integer = doSplit(integer);
  decimal = doSplit(decimal, false);
  return integer + (decimal === "" ? "" : "." + decimal);
}

export function MoniepointTask(props: MoniepointTaskProps): ReactNode {
  const dispatch = useAppDispatch();
  const { loading, accounts, current } = useAppSelector((state) => state.moniepointTask);

  useEffect(() => {
    dispatch(moniepointAccounts());
    dispatch(moniepointCurrent());
  }, []);

  const d = accounts.data.length > 0 ? accounts.data[0] : null;

  return (
    <Card title="支付管理">
      <Table
        bordered
        size="small"
        rowKey="id"
        loading={loading.accounts || loading.current}
        dataSource={accounts.data}
        pagination={{
          pageSize: accounts.size,
          total: accounts.total,
          current: accounts.current,
        }}
        columns={[
          {
            dataIndex: "id",
            title: "ID",
            align: "center",
          },
          {
            dataIndex: "phoneNumber",
            title: "登录电话号码",
            align: "center",
          },
          {
            dataIndex: "loginUsername",
            title: "登录用户名",
            align: "center",
          },
          {
            dataIndex: "customerName",
            title: "账户名称",
            align: "center",
          },
          {
            dataIndex: "account",
            title: "银行账户",
            align: "center",
          },
          {
            dataIndex: "paymentCount",
            title: `支付总额${
              d !== null
                ? `(${dayjs(d.paymentCountSince).format("DD/HH:mm")}~${dayjs(d.paymentCountUntil).format("DD/HH:mm")})`
                : ""
            }`,
            align: "center",
            render(value, record, index) {
              var d = new Decimal(record.paymentCount).div(100);
              return formatNumberWithCommas(d.toString());
            },
          },
          {
            dataIndex: "id",
            title: "状态",
            align: "center",
            render(value, record, index) {
              if (current.includes(record.phoneNumber)) {
                return <Tag color="success">已激活</Tag>;
              }
              return <Tag color="default">未激活</Tag>;
            },
          },
          {
            dataIndex: "id",
            title: "操作",
            align: "center",
            render(value, record, index) {
              const s = loading.active !== null && loading.active === record.phoneNumber;
              return (
                <Button
                  type="primary"
                  size="small"
                  danger={current.includes(record.phoneNumber)}
                  style={{ padding: 2 }}
                  disabled={s}
                  loading={s}
                  onClick={() =>
                    dispatch(
                      current.includes(record.phoneNumber)
                        ? moniepointDisable(record.phoneNumber)
                        : moniepointActive(record.phoneNumber)
                    )
                  }
                >
                  {current.includes(record.phoneNumber) ? "禁用" : "激活"}
                </Button>
              );
            },
          },
        ]}
      />
    </Card>
  );
}
