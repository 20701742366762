import React, { ReactNode, useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Space, Table } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import { consumeCodeConsume, consumeCodeFindByCode, consumeCodeRecord } from "fp/store/slice/consumeCode";
import dayjs, { Dayjs } from "dayjs";

export interface SMSConsumeCodeRecordProps {}
export interface SMSConsumeCodeRecordViewState {
  since: Dayjs;
  until: Dayjs;
  code: string;
}

type FormData = {
  code: string;
};

export function SMSConsumeCodeRecord(props: SMSConsumeCodeRecordProps): ReactNode {
  const [state, setState] = useState<SMSConsumeCodeRecordViewState>({
    since: dayjs().add(-7, "days").startOf("day"),
    until: dayjs().endOf("day"),
    code: "",
  });

  const [form] = Form.useForm<FormData>();
  const fv = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    loading,
    record: { data: pagination },
    model,
  } = useAppSelector((state) => state.consumeCode);

  useEffect(() => {
    form.validateFields().then(
      (v) => setSubmittable(true),
      (e) => setSubmittable(false)
    );
  }, [fv]);

  useEffect(() => {
    dispatch(
      consumeCodeRecord({
        current: pagination.current,
        size: pagination.size,
        since: state.since.valueOf(),
        until: state.until.valueOf(),
        query: "",
      })
    );
  }, []);

  return (
    <Card title="短信核销码">
      <Row gutter={24} justify="space-between">
        <Col>
          <Space>
            <Input placeholder="输入核销码" onChange={(e) => setState({ ...state, code: e.target.value.trim() })} />
            <Button
              type="primary"
              loading={loading.consume}
              disabled={loading.consume || !state.code}
              onClick={() => dispatch(consumeCodeConsume({ code: state.code }))}
            >
              核销
            </Button>
          </Space>
        </Col>
        <Col>
          <Form<FormData>
            form={form}
            initialValues={{ code: "" }}
            layout="inline"
            onFinish={(v) => {
              if (v.code.trim()) {
                dispatch(consumeCodeFindByCode({ code: v.code }));
                return;
              }

              dispatch(
                consumeCodeRecord({
                  current: pagination.current,
                  size: pagination.size,
                  since: state.since.valueOf(),
                  until: state.until.valueOf(),
                  query: "",
                })
              );
            }}
          >
            <Form.Item<FormData> name="code">
              <Input placeholder="核销码" />
            </Form.Item>
            <Form.Item<FormData>>
              <DatePicker
                defaultValue={state.since}
                onChange={(date) => setState({ ...state, since: date.clone() })}
                placeholder="开始时期"
              />
            </Form.Item>
            <Form.Item<FormData>>
              <DatePicker
                defaultValue={state.until}
                onChange={(date) => setState({ ...state, until: date.clone() })}
                placeholder="结束日期"
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" disabled={!submittable || loading.record}>
                查询
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Table
        bordered
        size="small"
        rowKey="id"
        dataSource={pagination.data}
        loading={loading.record}
        pagination={{
          current: pagination.current,
          pageSize: pagination.size,
          total: pagination.total,
          showSizeChanger: true,
          showQuickJumper: true,
          showPrevNextJumpers: true,
          onChange(page, pageSize) {
            dispatch(
              consumeCodeRecord({
                current: page,
                size: pageSize,
                since: state.since.valueOf(),
                until: state.until.valueOf(),
                query: "",
              })
            );
          },
        }}
        columns={[
          {
            title: "核销码",
            dataIndex: "id",
            align: "center",
            width: "15%",
          },
          {
            title: "核销次数",
            dataIndex: "times",
            align: "center",
            width: "15%",
          },
          {
            title: "上次核销时间",
            dataIndex: "lastConsumeTime",
            align: "center",
            width: "15%",
            render(value, record, index) {
              return dayjs(record.lastConsumeTime).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            title: "创建时间",
            dataIndex: "createTime",
            align: "center",
            width: "15%",
            render(value, record, index) {
              return dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss");
            },
          },
        ]}
      />
    </Card>
  );
}
