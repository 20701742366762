import { Alert, Card, Col, Input, Modal, Row, Typography } from "antd";
import { OTPRef } from "antd/es/input/OTP";
import { createRef, FC, ReactNode, useEffect, useState } from "react";

export interface SecurityCodeProps {
  length?: number;
  onApply?: (code: string) => void;
  children?: ReactNode;
}

const DefaultSecurityCodeProps: SecurityCodeProps = {
  length: 4,
  onApply: (code: string) => {},
};

export const SecurityCode: FC<SecurityCodeProps> = function (props: SecurityCodeProps): ReactNode {
  const p = props as Required<SecurityCodeProps>;
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Modal
        title="请输入安全码"
        width="50%"
        style={{ maxWidth: 500 }}
        destroyOnClose
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={() => setOpenModal(false)}
      >
        <Card>
          <Row justify="center">
            <Col>
              <Input.OTP
                autoFocus
                length={p.length}
                onChange={(v) => {
                  p.onApply(v);
                  setOpenModal(false);
                }}
              />
            </Col>
          </Row>
          <div style={{ paddingTop: 20, textAlign: "right" }}>
            <Typography.Text>请联系管理员获得安全码，一个安全码只能使用一次</Typography.Text>
          </div>
        </Card>
      </Modal>
      <span onClick={() => setOpenModal(true)}>{props.children}</span>
    </>
  );
};

SecurityCode.defaultProps = DefaultSecurityCodeProps;
