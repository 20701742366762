import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var traverseFileTree = function traverseFileTree(files, callback, isAccepted) {
  var flattenFileList = [];
  var progressFileList = [];
  files.forEach(function (file) {
    return progressFileList.push(file.webkitGetAsEntry());
  });
  function loopFiles(item) {
    var dirReader = item.createReader();
    function sequence() {
      dirReader.readEntries(function (entries) {
        var entryList = Array.prototype.slice.apply(entries);
        progressFileList.push.apply(progressFileList, _toConsumableArray(entryList));
        // Check if all the file has been viewed
        var isFinished = !entryList.length;
        if (!isFinished) {
          sequence();
        }
      });
    }
    sequence();
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  var _traverseFileTree = function _traverseFileTree(item, path) {
    if (!item) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    item.path = path || '';
    if (item.isFile) {
      item.file(function (file) {
        if (isAccepted(file)) {
          // https://github.com/ant-design/ant-design/issues/16426
          if (item.fullPath && !file.webkitRelativePath) {
            Object.defineProperties(file, {
              webkitRelativePath: {
                writable: true
              }
            });
            // eslint-disable-next-line no-param-reassign
            file.webkitRelativePath = item.fullPath.replace(/^\//, '');
            Object.defineProperties(file, {
              webkitRelativePath: {
                writable: false
              }
            });
          }
          flattenFileList.push(file);
        }
      });
    } else if (item.isDirectory) {
      loopFiles(item);
    }
  };
  function walkFiles() {
    var wipIndex = 0;
    while (wipIndex < progressFileList.length) {
      _traverseFileTree(progressFileList[wipIndex]);
      wipIndex++;
    }
    callback(flattenFileList);
  }
  walkFiles();
};
export default traverseFileTree;