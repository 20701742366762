import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Services } from "fp/store/Services";
import { FMPayPaginationTimeRnageQuery, FMPayResponsePagination } from "fp/store/type";
import { SMSConsumeCodeModel } from "fp/components/sms/BulkSMSStatus";

export const consumeCodeConsume = createAsyncThunk<SMSConsumeCodeModel, { code: string }>(
  "consume.code.consume",
  async (p) => {
    const resp = await Services.SMSConsumeCodeModel.consume(p.code);
    return resp.data;
  }
);

export const consumeCodeRecord = createAsyncThunk<
  FMPayResponsePagination<SMSConsumeCodeModel>,
  FMPayPaginationTimeRnageQuery<string>
>("consume.code.record", async (p) => {
  const resp = await Services.SMSConsumeCodeModel.pagination(p);
  return resp;
});

export const consumeCodeFindByCode = createAsyncThunk<SMSConsumeCodeModel, { code: string }>(
  "consume.code/find.by.code",
  async (p) => {
    const resp = await Services.SMSConsumeCodeModel.findByCode(p.code);
    return resp.data;
  }
);

export interface ConsumeCodeState {
  model: SMSConsumeCodeModel | null;
  record: FMPayResponsePagination<SMSConsumeCodeModel>;
  loading: {
    consume: boolean;
    record: boolean;
  };
}

const InitialState: ConsumeCodeState = {
  model: null,
  record: {
    message: "",
    status: "",
    success: true,
    data: {
      current: 1,
      size: 10,
      total: 0,
      pages: 0,
      latest: false,
      data: [],
    },
  },
  loading: {
    record: false,
    consume: false,
  },
};

export const consumeCodeSlice = createSlice({
  name: "consumeCode",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(consumeCodeConsume.pending, (state) => {
      state.loading.consume = true;
    });
    builder.addCase(consumeCodeConsume.rejected, (state) => {
      state.loading.consume = false;
    });
    builder.addCase(consumeCodeConsume.fulfilled, (state, action) => {
      state.loading.consume = false;
      state.record.data.current = 1;
      state.record.data.latest = true;
      state.record.data.total = 1;
      state.record.data.pages = 1;

      if (action.payload) {
        state.model = action.payload;
        state.record.data.data = [action.payload];
      } else {
        state.model = null;
        state.record.data.data = [];
      }
    });

    builder.addCase(consumeCodeRecord.pending, (state) => {
      state.loading.record = true;
    });
    builder.addCase(consumeCodeRecord.rejected, (state) => {
      state.loading.record = false;
    });
    builder.addCase(consumeCodeRecord.fulfilled, (state, action) => {
      state.loading.record = false;
      state.record = action.payload;
    });

    builder.addCase(consumeCodeFindByCode.pending, (state) => {
      state.loading.record = true;
    });
    builder.addCase(consumeCodeFindByCode.rejected, (state) => {
      state.loading.record = false;
    });
    builder.addCase(consumeCodeFindByCode.fulfilled, (state, action) => {
      state.loading.record = false;
      state.record.data.current = 1;
      state.record.data.latest = true;
      state.record.data.total = 1;
      state.record.data.pages = 1;

      if (action.payload) {
        state.record.data.data = [action.payload];
      } else {
        state.record.data.data = [];
      }
    });
  },
});

export default consumeCodeSlice.reducer;
