import { ReactNode, useEffect, useState } from "react";
import { Button, Card, DatePicker, Divider, Form, Input, Row, Select, Table, Tag, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "fp/store/hooks";
import { bulkSMSMessageReport } from "fp/store/slice/bulkSMS";
import { BulkDLRStatus, BulkSMSMessageReportQuery } from "./BulkSMSStatus";
import dayjs, { Dayjs } from "dayjs";

export interface BulkSMSMessageReportProps {}

interface BulkSMSMessageReportViewState {
  since: Dayjs;
  until: Dayjs;
}

type FormData = Omit<BulkSMSMessageReportQuery, "page" | "since" | "until">;

export function BulkSMSMessageReport(props: BulkSMSMessageReportProps): ReactNode {
  const [state, setState] = useState<BulkSMSMessageReportViewState>({
    since: dayjs().add(-7, "days").startOf("day"),
    until: dayjs().endOf("day"),
  });
  const [form] = Form.useForm<FormData>();
  const fv = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const s = useAppSelector((state) => state.bulkSMS);

  const { loading, reports } = s;

  const DefaultFormData = {
    recipient: "",
    senderName: "",
    deliveryStatus: "",
  };

  useEffect(() => {
    form.validateFields().then(
      (v) => setSubmittable(true),
      (e) => setSubmittable(false)
    );
  }, [fv]);

  useEffect(() => {
    form.setFieldsValue({
      recipient: "",
      senderName: "",
      deliveryStatus: "",
    });
    dispatch(
      bulkSMSMessageReport({
        page: reports.page,
        since: state.since.format("YYYY-MM-DD"),
        until: state.until.format("YYYY-MM-DD"),
        ...DefaultFormData,
      })
    );
  }, []);

  return (
    <Card title="已发短信记录">
      <Form<FormData>
        form={form}
        initialValues={{
          recipient: "",
          senderName: "",
          deliveryStatus: "",
        }}
        autoComplete="off"
        layout="inline"
        onFinish={(v) => {
          dispatch(
            bulkSMSMessageReport({
              page: reports.page,
              since: state.since.format("YYYY-MM-DD"),
              until: state.until.format("YYYY-MM-DD"),
              ...v,
            })
          );
        }}
      >
        <Form.Item<FormData> name="recipient">
          <Input placeholder="接收号码" />
        </Form.Item>
        <Form.Item<FormData> name="senderName">
          <Input placeholder="发送人名称" />
        </Form.Item>
        <Form.Item<FormData>>
          <DatePicker
            defaultValue={state.since}
            onChange={(date) => setState({ ...state, since: date.clone() })}
            placeholder="开始时期"
          />
        </Form.Item>
        <Form.Item<FormData>>
          <DatePicker
            defaultValue={state.until}
            onChange={(date) => setState({ ...state, until: date.clone() })}
            placeholder="结束日期"
          />
        </Form.Item>
        <Form.Item<FormData> name="deliveryStatus">
          <Select style={{ minWidth: 120 }} placeholder="状态">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="1">Delivered</Select.Option>
            <Select.Option value="2">Send</Select.Option>
            <Select.Option value="18">DeliveredPP</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" disabled={!submittable || loading.reports}>
            查询
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Table
        bordered
        size="small"
        dataSource={reports.data}
        loading={loading.reports}
        pagination={{
          showSizeChanger: false,
          current: reports.page,
          total: reports.total,
          pageSize: 100,
          onChange(page, pageSize) {
            dispatch(
              bulkSMSMessageReport({
                page,
                since: state.since.format("YYYY-MM-DD"),
                until: state.until.format("YYYY-MM-DD"),
                ...form.getFieldsValue(),
              })
            );
          },
        }}
        columns={[
          {
            title: "消息ID",
            dataIndex: "messageid",
            align: "center",
          },
          {
            title: "接收号码",
            dataIndex: "recipient",
            align: "center",
          },
          {
            title: "发送人名称",
            dataIndex: "sender_name",
            align: "center",
          },
          {
            title: "状态",
            dataIndex: "status",
            align: "center",
            render(value, record, index) {
              return <Tag color={record.status === BulkDLRStatus.SEND ? "green" : "default"}>{record.status}</Tag>;
            },
          },
          {
            title: "远程消息ID",
            dataIndex: "remotemsgid",
            align: "center",
          },
          {
            title: "发送时间",
            dataIndex: "disptachtime",
            align: "center",
          },
          {
            title: "短信内容",
            dataIndex: "content",
            align: "center",
            render(value, record, index) {
              return (
                <Typography.Text
                  copyable
                  style={{ maxWidth: 200 }}
                  ellipsis={{
                    tooltip: { title: record.content, color: "blue" },
                  }}
                >
                  {record.content}
                </Typography.Text>
              );
            },
          },
        ]}
      />
    </Card>
  );
}
